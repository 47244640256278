import * as THREE from "three";
import React from "react";
import { gsap } from "gsap";
import { useEffect, useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { init } from "@emailjs/browser";
import ScrollTrigger from "gsap/ScrollTrigger";

const Computer3DModel = (props) => {
  const threeDSpaceContainerRef = useRef();
  let model;
  let camera;
  const ref = useRef();
  const sceneref = useRef();
  ref.current = props.theme;

  function setTheme(value) {
    if (!value) {
      sceneref.current.background.set(0x202020);
    } else {
      sceneref.current.background.set(0xf6d4b1);
    }
  }

  useEffect(() => {
    let scene;
    let light;
    let pointLight;
    let screenMesh;
    let renderer;
    let screenVector = new THREE.Vector3();

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;

    let isMobileDevice = regexp.test(details);

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf6d4b1);
    sceneref.current = scene;

    camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight
    );
    scene.add(camera);

    light = new THREE.AmbientLight(0x404040); // soft white lig
    scene.add(light);
    pointLight = new THREE.PointLight(0xffffff, 2);
    pointLight.position.set(1.5, 8, 0.5);
    pointLight.intensity = 2;

    scene.add(pointLight);

    const path = "untitled.glb";

    const loader = new GLTFLoader();
    loader.load(path, function (gltf) {
      model = gltf.scene;
      scene.add(model);
      model.traverse(function (obj) {
        if (obj instanceof THREE.Mesh) {
          if (obj.name.toLowerCase().includes("mesh_0")) {
            screenMesh = obj;
            screenMesh.getWorldPosition(screenVector);
            var boundingBox = new THREE.Box3();
            var mesh = screenMesh;
            mesh.updateMatrixWorld(true);
            boundingBox.copy(mesh.geometry.boundingBox);
            boundingBox.applyMatrix4(mesh.matrixWorld);
            boundingBox.getCenter(screenVector);
            if (isMobileDevice) {
              if (window.innerHeight < window.innerWidth) {
                camera.position.set(
                  screenVector.x,
                  screenVector.y,
                  screenVector.z + 1.2
                );
              } else {
                camera.rotation.z = Math.PI * -0.5;
                camera.position.set(
                  screenVector.x,
                  screenVector.y,
                  screenVector.z + 2.4
                );
              }
            } else
              camera.position.set(
                screenVector.x,
                screenVector.y,
                screenVector.z + 1.4
              );
          }
        }
      });
    });

    gsap.registerPlugin(ScrollTrigger);

    let anim = gsap.timeline({
      scrollTrigger: {
        trigger: "#three",
        toggleActions: "play pause reverse pause",
        scrub: true,
        pin: true,
        onEnterBack: () => {
          props.setAnimationState(true);
        },
        onLeave: () => {
          props.setAnimationState(false);
        },
        snap: 0.1,
        start: "center center",
        end: "+=300",
      },
    });

    function modelAnimation(event) {
      anim.to(
        camera.position,
        {
          z: 8,
          ease: "slow",
          duration: 3,
        },
        0
      );
      anim.to(
        model.rotation,
        {
          y: Math.PI * 0.2,
          ease: "slow",

          duration: 3,
        },
        0
      );
      anim.to(
        model.visibility,
        {
          opacity: 0,
          ease: "slow",
          duration: 3,
        },
        0
      );
    }
    setTimeout(() => props.setLoading(false), 3000);

    renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.shadowMap.enabled = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    threeDSpaceContainerRef.current.appendChild(renderer.domElement);

    init();
    render();

    window.addEventListener("resize", onWindowResize);

    threeDSpaceContainerRef.current.addEventListener("wheel", modelAnimation);

    if (isMobileDevice) {
      document.body.onscroll = function (e) {
        anim.to(
          camera.position,
          {
            z: 8,
            ease: "slow",

            duration: 3,
          },
          0
        );
        anim.to(
          model.rotation,
          {
            y: Math.PI * 0.2,
            ease: "slow",

            duration: 3,
          },
          0
        );
        anim.to(
          camera.rotation,
          {
            z: Math.PI * 0.01,
            ease: "slow",

            duration: 3,
          },
          0
        );
      };

      document.body.ontouchmove = function (e) {
        anim.to(
          camera.position,
          {
            z: 8,
            ease: "slow",

            duration: 3,
          },
          0
        );
        anim.to(
          model.rotation,
          {
            y: Math.PI * 0.2,
            ease: "slow",

            duration: 3,
          },
          0
        );
        anim.to(
          camera.rotation,
          {
            z: Math.PI * 0.01,
            ease: "slow",

            duration: 3,
          },
          0
        );
      };
    }

    function onWindowResize(renderer) {
      const canvas = renderer.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        renderer.setSize(width, height, false);
      }
      return needResize;
    }

    function render() {
      if (onWindowResize(renderer)) {
        const canvas = renderer.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;

        camera.updateProjectionMatrix();
      }
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    }
  }, []);

  useEffect(() => {
    setTheme(ref.current);
  }, [ref.current]);

  return <div id="gui" ref={threeDSpaceContainerRef}></div>;
};

export default Computer3DModel;
